<template>
  <div>
    <section>
      <v-snackbar v-model="snackbar.model" timeout="3000">{{
        snackbar.text
      }}</v-snackbar>
    </section>
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-form
                v-model="form2.isValid"
                ref="form2"
                @submit.prevent="updateTemplateConfig"
              >
                <Subheader icon="mdi-notebook" title="Textvorlagen"></Subheader>
                <div class="mt-7">
                  <v-card :loading="loading" :disabled="form2.isLoading">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <div class="mb-2">
                            Vorlagen für Tätigkeitsbezeichnungen in
                            Stundenmeldungen
                          </div>
                          <v-card
                            v-for="(titleTemplate, index) in accountingConfig
                              .timeSheets.titleTemplates"
                            :key="index"
                            dense
                            outlined
                            class="my-2"
                          >
                            <v-card-text class="pa-0">
                              <v-container>
                                <v-row>
                                  <v-col>
                                    <v-text-field
                                      v-model.trim="
                                        accountingConfig.timeSheets
                                          .titleTemplates[index]
                                      "
                                      outlined
                                      dense
                                      :rules="[rules.required]"
                                      hide-details="auto"
                                      label="Tätigkeitsbezeichnung"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="auto">
                                    <v-btn
                                      depressed
                                      text
                                      color="error"
                                      @click="deleteTitleTemplate(index)"
                                    >
                                      <v-icon left small>mdi-delete</v-icon
                                      >Löschen
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                          </v-card>
                          <v-btn
                            color="success"
                            text
                            outlined
                            @click="addTitleTemplate()"
                            ><v-icon left small>mdi-plus</v-icon
                            >Hinzufügen</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        color="success"
                        :disabled="!form2.isValid"
                        :loading="form2.isLoading"
                        @click="updateTemplateConfig()"
                        class="mr-2 my-2"
                      >
                        <v-icon left> mdi-content-save </v-icon>
                        Speichern
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
// import { db } from "@/firebase";
import { ACCOUNTING } from "@/store/modules.js";
import { UPDATE_ACCOUNTING_CONFIG } from "@/store/action-types.js";

export default {
  name: "accounting-config-tab-general",
  components: {
    Subheader,
  },
  data() {
    return {
      loading: true,
      snackbar: {
        model: false,
        text: "",
      },
      form2: {
        isValid: false,
        isLoading: false,
      },

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  computed: {
    accountingConfig() {
      return this.$store.state.organization.activeOrganization.config
        .accounting;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loading = false;
    },
    addTitleTemplate() {
      this.accountingConfig.timeSheets.titleTemplates.push("");
    },
    deleteTitleTemplate(index) {
      this.accountingConfig.timeSheets.titleTemplates.splice(index, 1);
    },
    updateTemplateConfig() {
      if (this.form2.isValid) {
        this.form2.isLoading = true;
        console.log(this.$route.params.organizationId);
        this.$store
          .dispatch(`${ACCOUNTING}${UPDATE_ACCOUNTING_CONFIG}`, {
            organizationId: this.$route.params.organizationId,

            type: "onlyTemplateConfig",
            timeSheets: {
              titleTemplates: this.accountingConfig.timeSheets.titleTemplates,
            },
          })
          .then(() => {
            this.form2.isLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = "Einstellungen erfolgreich aktualisiert.";
          })
          .catch((error) => {
            console.log(error);
            this.form2.isLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = error.message;
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>
